<template>
  <chip :class="['subsales-booking-status', getStatusClass()]">
    {{ status.name }}
  </chip>
</template>

<script>
import Chip from "@/components/GlobalComponents/Chip";
import { subsalesPurchaseStatus as STATUS } from "@/enums";

export default {
  components: {
    Chip
  },
  mixins: [],
  props: {
    status: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      STATUS
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    getStatusClass() {
      switch (Number(this.status.id)) {
        case STATUS.BOOKING:
          return "bg-warn fg-white";
        case STATUS.COMPLETED:
          return "bg-success fg-white";
        case STATUS.CANCELLED:
          return "bg-grey-700 fg-white";

        default:
          return "";
      }
    }
  }
};
</script>

<style lang="scss">
.subsales-booking-status {
}
</style>
